.vinkaSuperContainer {
    padding: 0 0 !important;
}

.vinkaPanelsContainer {
    margin: 5px 5px !important;
}

.vinkaPanel {
    overflow: auto;
    z-index: 1;
    pointer-events: auto;
    max-height: 90vh;
    height: 100%;
    border-radius: 0.5em;
}
