.vinkaModalOverlay {
    overflow: auto;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    /*background: rgba(0, 0, 0, .7);*/
    backdrop-filter: blur(1px);
}

.vinkaModalContent {
    background-color: rgba(0, 0, 0, 0) !important;
}

.vinkaModalSection {
    color: #dee2e6 !important;
    background-color: #343a40 !important;
    border-color: rgba(0, 0, 0, .15) !important;
}