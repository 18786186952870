/*
--------------------------------------------------------
 NAVBAR AND LINKS
 Override styles of react-boostrap NavLink
--------------------------------------------------------
*/
.vinkaNavBar {
    position: relative;
    left: 0;
    top: 0;
    z-index: 11;
    width: 100%;
    background-color: rgba(0,0,0, .72);
    backdrop-filter: blur(10px);
}

.vinkaBrand {
    font-weight: bold;
    color: #ffffff !important;
    /* Makes nav links and brand text on the same height */
    padding: 0 0 0 0 !important;

}

/* Selects "Flex" text next to Vinka logo */
.vinkaBrand > span {
    transition: all 0.1s linear 0.01s;
}

.vinkaBrand > svg {
    transition: all 0.1s linear 0.01s;
    width: 6em;
    fill: #FFFFFF;
}

.vinkaBrand:hover {
    color: #808080FF !important;
    fill: #808080FF !important;
    cursor: pointer;
}

.vinkaBrand:hover > svg {
    fill: #808080FF !important;
}

.navLink {
    color: #ffffff !important;
    transition: all 0.1s linear 0.01s;
    vertical-align: text-bottom;
}

/* Position the text of the link to be separated and aligned with icon */
.navLink > svg {
    margin-right: 0.4em;
    margin-bottom: 0.25em;
}

.navLink:hover {
    color: #808080FF !important;
}

/* Aligns the dropdown button to the right */
.settingsDropdown {
    /*margin-left: auto;*/
}

/*
--------------------------------------------------------
SETTINGS ICON & BUTTON
Override class dropdown-toggle, btn, btn-primary
--------------------------------------------------------
*/
.settingsIcon {
    fill: #FFF;
    height: 1.5em;
    width: 1.5em;
    transition: all 0.1s linear 0.01s;
}


.settingsDropdownToggle {
    background-color: rgba(0, 0, 0, 0) !important;
    border-color: rgba(0, 0, 0, 0) !important;
}

.settingsDropdownToggle:hover > svg {
    fill: #808080FF;
}

.settingsDropdownToggle:focus {
    box-shadow: none !important;
}


/*
--------------------------------------------------------
SETTINGS DROPDOWN
Override styles of react-bootstrap DropdownMenu
--------------------------------------------------------
*/


.settingsDropdownMenu {
    border-radius: 0.5em;
    color: #dee2e6 !important;
    background-color: #343a40 !important;
    border-color: rgba(0,0,0,.15) !important;
    /*backdrop-filter: drop-shadow(4px 4px 10px blue);*/
}

.settingsDropdownItem {
    color: #ffffff !important;
}

.settingsDropdownItem:hover {
    background-color: rgba(255,255,255,.15) !important;
}

.settingsDropdownDivider {
    margin: .5rem 0 !important;
    padding-left: 1em !important;
    border-top: 1px solid rgba(0,0,0,.15) !important;
}