.backgroundMap {
    left: 0;
    top: 0;
    z-index: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
}

.contextMenuContainer {
    z-index: 1000;
    position: absolute;
    font-size: 1.5em;
    border-radius: 0.5em;
    padding: 0.25em 0;

    color: #dee2e6 !important;
    background-color: #343a40 !important;
    border-color: rgba(0,0,0,.15) !important;
}

.contextMenuItem {
    color: #ffffff !important;
}

.contextMenuItem:hover {
    background-color: rgba(255,255,255,.15) !important;
}

.contextMenuDivider {
    margin: .5rem 0 !important;
    padding-left: 1em !important;
    border-top: 1px solid rgba(0,0,0,.15) !important;
}