.notificationsContainer {
    z-index: 11;
    position: absolute;
    margin-left: calc(50vw - min(700px, 50vw) / 2);
}

.notification {
    width: 50vw;
    max-width: 700px;
    margin-bottom: 0.25em;
    border-radius: 0.5em;
    padding: 0.5em;
    color: white;
    text-align: center;
    cursor: pointer;
}

.info {
    background-color: #bac67b;
    color: #303410;
}

.info .notificationProgressBar {
    background-color: #79801e;
}

.warn {
    background-color: #ffd56b;
    color: #574414;
}

.warn .notificationProgressBar {
    background-color: #eab22d;
}

.error {
    background-color: #ff7b54;
    color: #61210d;
}

.error .notificationProgressBar {
    background-color: #dc350c;
}

.progressContainer {
    margin-top: 0.5em;
    height: 5px;
    position: relative;
    background: rgba(0,0,0,0);
    overflow: hidden;
}

.progressContainer span {
    display: block;
    height: 100%;
}

.notificationProgressBar {
    animation: progressBar ease-out;
    animation-fill-mode:both;
}

@keyframes progressBar {
    0% { width: 0; }
    100% { width: 100%; }
}