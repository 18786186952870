/* By react app */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vinkaSpinner {
    animation: spin infinite 2s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Remove caret after settings icon in navbar. Tried having this in NavBar.module.css, but wouldn't work*/
#settings-dropdown-toggle::after {
    display: none !important;
}

/* width */
*::-webkit-scrollbar {
    width: 0.6em;
}

/* Track */
*::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 0.5em;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background-color: rgba(109, 109, 109, 0.72);
    border-radius: 0.5em;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    background: rgba(170, 170, 170, 0.6);
}

