.VinkaTable {
    background: rgba(0,0,0,0);
}

.actionContainer {
    float: right;
}

.actionIcon {
    height: 1.25em;
    width: 1.25em;
    margin: 0.2em;
    color: #ffffff;
    transition: all 0.05s linear .05s;
    cursor: pointer;
}

.disabledActionIcon {
    cursor: auto;
    color: #808080FF !important;
}

.actionIcon:hover {
    color: #808080FF;
}

.deleteActionIcon:hover {
    color: #a73e3e !important;
}

.pinActionIcon {
    color: #808080FF;
}

.pinActionIcon:hover {
    color: #3f732b;
}

.pinActive {
    color: #4dc023;
}

.tableLink {
    cursor: pointer;
}
