.vinkaPanelWindow {
    padding: 1em;
    border-radius: 0.5em;
    background-color: rgba(0, 0, 0, 0.72);
    backdrop-filter: blur(10px);
    font-size: 0.8em;
    color: #ffffff;
}

.vinkaPanelWindowTitle {
    font-size: 2.25em;
}

/*
    Apply bottom margin to all windows within panel, except last one.
    Meant to separate the windows, but omitting the last one makes the
    scrollbar look nicer on overflow.
*/
.vinkaPanelWindow:not(:last-child) {
    margin-bottom: 5px;
}

.vinkaWindowButton {
    color: #FFFFFF;

    align-self: start;
    background: none !important;
    border: none !important;
    padding: 0.25em 0.25em !important;
}

.vinkaSaveButtonDirty {
    color: #ffd56b;
}

.vinkaSaveButtonError {
    color: #ff7b54 !important;
}

.vinkaWindowButton:first-child {
    margin-left: auto;
}

.vinkaWindowButton > svg {
    width: 1.25em;
    height: 1.25em;

}

.vinkaWindowButton:focus {
    background-color: rgba(0, 0, 0, 0) !important;
    border-color: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
}

.vinkaWindowButton:hover {
    background-color: #585858 !important;
}

/*
    Makes plus (+) button into close (x) button
 */
.vinkaWindowCloseButton > svg {
    transform: rotate(45deg);
}

.vinkaWindowCloseButton:hover {
    background-color: #5e4242 !important;

}
